.login-wrap {
  height: calc(100vh - 136px);
  background: #f2f2f2;
}
.login-wrap .arco-form-item {
  margin-bottom: 0;
}
.login-wrap .login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 680px;
  height: 700px;
  padding: 24px;
  border-radius: var(--Radius32, 32px);
  background-color: #fff;
}
.login-wrap .login-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
}
.login-wrap .login-from {
  width: 314px;
  margin: 24px auto 0;
  display: flex;
  gap: 24px;
}
.login-wrap .login-from-button {
  border-radius: var(--12, 12px);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: 56px;
  padding: var(--12, 12px) var(--24, 24px);
  justify-content: center;
  align-items: center;
  gap: var(--8, 8px);
}
.login-wrap .login-links-content {
  width: 314px;
  margin: 4px auto 0;
  text-align: center;
}
.login-wrap .login .link-button {
  font-size: 14px;
  line-height: 28px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.1s linear;
  color: rgb(153, 153, 153);
}
.login-wrap .logo-content {
  margin: 80px 0 24px;
  display: flex;
  justify-content: center;
}
.login-wrap .logo-content .hkgai-icon-logo {
  width: 80px;
  height: 77px;
}
.login-wrap .arco-divider-horizontal {
  min-width: 314px;
  width: 314px;
  margin: 24px auto 0;
}
.login-wrap .input-text,
.login-wrap .arco-input-inner-wrapper {
  border: 1px solid var(--text-3, rgb(134, 144, 156));
  background: var(--white-80, rgba(255, 255, 255, 0.8));
}
.login-wrap .arco-icon {
  font-size: 18px !important;
}
.login-wrap .arco-input {
  line-height: 24px;
  padding: 16px 0;
  caret-color: var(--Color, rgb(22, 93, 255));
}
.login-wrap .input-text {
  border-radius: 16px;
  padding-left: 16px;
}
.login-wrap .arco-input-group > :last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.login-wrap .arco-input-group > :first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}